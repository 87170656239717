import { fetchConfig } from '@/lib/fetchConfig';
import { useQuery } from '@tanstack/react-query';

export const queryStories = `query($category: String! ) {
  articleCollection(limit: 100, where: {category: $category}){
    items{
      ...on Article{
        slug
        seo {
          title
          description
          canonicalUrl
          nextPage
        }
      }
    }
  }
}
`;

export const queryStory = `query ($locale: String!, $category: String!, $slug: String!) {
  articleCollection(
    limit: 1
    locale: $locale
    where: {category: $category, slug: $slug}
  ) {
    items {
      ... on Article {
        slug
        subCategory
        category
        landingPage
        featuredTitle
        featuredDescription {
          json
        }
        published
        writtenBy
        accordion {
          json
          links {
            assets {
              hyperlink {
                url
              }
              block {
                sys {
                  id
                }
                url
              }
            }
            entries {
              block {
                sys {
                  id
                }
                __typename
                ... on CardSection {
                  __typename
                  internalTitle
                  title
                  layout
                  sectionContentCollection(limit: 10) {
                    items {
                      ... on Card {
                        __typename
                        onlyImagevideo
                        cardTemplate
                        linkUrl
                        linkIconTitle
                        title
                        videoUrl
                        shortDescription {
                          json
                        }
                        cardImage {
                          width
                          height
                          url
                          title
                        }
                      }
                      ... on Accordion {
                        __typename
                        title
                        description {
                          json
                        }
                      }
                    }
                  }
                }
                ... on ContentFlag {
                  __typename
                  internalTitle
                  isConsulteerPage
                }
              }
            }
          }
        }
      }
      virtualShowRoom {
        ... on Card {
          __typename
          cardTemplate
          cardImage {
            title
            width
            height
            url
          }
          shortDescription {
            json
          }
          linkUrl
          title
          internalTitle
          linkIconTitle
          linkMailto
          cardImage {
            width
            height
            url
            title
          }
          icon {
            url
            title
            width
            height
          }
        }
      }
      featuredProjectsCollection {
        items {
          __typename
          ... on ArticleSection {
            layout
            title
            sectionContentCollection {
              items {
                ... on Article {
                  slug
                  category
                  subCategory
                  featuredTitle
                  featuredDescription {
                    json
                  }
                  featuredImage {
                    title
                    url
                    width
                    height
                  }
                }
              }
            }
          }
        }
      }
      nextPageNavigation {
        internalTitle
        buttonText
        buttonUrl
      }
      sidebarNextPageNavigationCollection {
        items {
          popupButton
          internalTitle
          buttonText
          buttonUrl
        }
      }
      personBanner {
        ... on PersonBanner {
          internalTitle
          title
          quote
          personName
          personPosition
          picturePosition
          personMail
          pictureOfPerson {
            title
            description
            width
            height
            url
          }
          linkedIn {
            title
            urlPath
            document {
              title
              description
              url
              width
              height
            }
          }
        }
      }
      seo {
        title
        description
        canonicalUrl
        nextPage
        noindex
        nofollow
        image {
          url
          width
          height
          title
          description
        }
        ldAuthor
        ldAuthorUrl
        ldPublished
        ldUpdated
      }
    }
  }
}`;

export const queryContentStorie = `query ($locale: String!, $category: String!, $slug: String!) {
  articleCollection(
    limit: 1
    locale: $locale
    where: {category: $category, slug: $slug}
  ) {
    items {
      ... on Article {
        content {
          json
          links {
            assets {
              hyperlink {
                url
              }
              block {
                sys {
                  id
                }
                url
              }
            }
            entries {
              block {
                sys {
                  id
                }
                __typename
                ... on PicturesInRow {
                  title
                  imagesCollection {
                    items {
                      url
                    }
                  }
                }
                ... on CardSection {
                  __typename
                  title
                  layout
                  sectionContentCollection(limit: 20) {
                    items {
                      ... on Card {
                        __typename
                        onlyImagevideo
                        cardTemplate
                        linkUrl
                        title
                        videoUrl
                        shortDescription {
                          json
                        }
                        cardImage {
                          width
                          height
                          url
                          title
                        }
                      }
                      ... on Accordion {
                        __typename
                        title
                        description {
                          json
                        }
                      }
                    }
                  }
                }
                ... on Quote {
                  quoteText
                }
                ... on YouTubeVideo {
                  videoUrl
                }
                ... on Card {
                  videoUrl
                }
                __typename
                ... on PictureAndTextInRow {
                  titleRow
                  description {
                    json
                  }
                  picturePosition
                  picture {
                    title
                    url
                    width
                    height
                  }
                  pictureAndTextInRowContent {
                    json
                    links {
                      assets {
                        hyperlink {
                          url
                        }
                        block {
                          sys {
                            id
                          }
                          url
                        }
                      }
                      entries {
                        block {
                          sys {
                            id
                          }
                          __typename
                          ... on NavigationButton {
                            __typename
                            popupButton
                            internalTitle
                            buttonText
                            buttonUrl
                            showTitle
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}`;

const fetchArticle = async (locale: string, category: string, slug: string) => {
  const variables = { locale, category, slug };
  const res = await fetch(fetchConfig.endpoint as string, {
    method: 'POST',
    ...fetchConfig.params,
    body: JSON.stringify({ query: queryStory, variables })
  });
  const json = await res.json();

  if (json.errors) {
    const { message } = json.errors[0];

    throw new Error(message);
  }

  return json.data;
};

const fetchArticleContent = async (
  locale: string,
  category: string,
  slug: string
) => {
  const variables = { locale, category, slug };
  const res = await fetch(fetchConfig.endpoint as string, {
    method: 'POST',
    ...fetchConfig.params,
    body: JSON.stringify({ query: queryContentStorie, variables })
  });
  const json = await res.json();

  if (json.errors) {
    const { message } = json.errors[0];

    throw new Error(message);
  }

  return json.data;
};

const useArticleData = (locale: any, category: string, slug: any) => {
  return useQuery({
    queryKey: [slug, locale],
    refetchOnWindowFocus: false,
    queryFn: () => fetchArticle(locale, category, slug)
  });
};

const useArticleContentData = (locale: any, category: string, slug: any) => {
  return useQuery({
    queryKey: [slug, locale, category],
    refetchOnWindowFocus: false,
    queryFn: () => fetchArticleContent(locale, category, slug)
  });
};

export {
  useArticleData,
  useArticleContentData,
  fetchArticle,
  fetchArticleContent
};
